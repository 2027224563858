<template>
<div class="tw-flex tw-w-full tw-flex-col lg:">
  <icons name="arrow-back" />
  <div class="company-dashboard lg:tw-mt-10 tw-mt-5 tw-p-5">
    <div class="tw-flex tw-justify-between tw-w-full tw-items-center tw-flex-wrap">
      <h6 class="header">Wait list</h6>
      <div
          class="mr-2 tw-mt-5 lg:tw-mt-0 "
          style="border-radius: 10px; border: 1px solid rgba(79, 79, 79, 0.08)"
      >
        <v-text-field
            placeholder="Search"
            class="p-0"
            solo
            flat
            hide-details
            v-model="search"
        >
          <template #prepend>
            <v-icon :color="color" class="ml-5">
              mdi-magnify
            </v-icon>
          </template>
          <template #append>
            <v-icon :color="color">
              mdi-filter-variant
            </v-icon>
          </template>
        </v-text-field>
      </div>
      <v-data-table
          :headers="headers"
          :mobile-breakpoint="0"
          :search="search"
          item-key="id"
          :single-select="false"
          height="available"
          :hide-default-footer ="waitListData.length<=10"
          :items="waitListData"
          :no-data-text="not_found"
          class="mt-4 mx-1 table"
          :loading="loading"

      >
        <template #header.avatar>
          <span class="status"></span>
        </template>
        <template v-slot:[`item.avatar`]="{ item }">
          <avatar :avatar="item.firstName.charAt(0)"/>
        </template>
        <template #header.firstName>
          <span class="status">First Name</span>
        </template>
        <template v-slot:[`item.firstName`]="{ item }">
          <span class="td-style">{{ item.firstName }}</span>
        </template>

        <template #header.lastName>
          <span class="status">Last Name</span>
        </template>
        <template v-slot:[`item.lastName`]="{ item }">
          <span class="td-style">{{ item.lastName }}</span>
        </template>
        <template #header.email>
          <span class="status">Email</span>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <span class="td-style">{{ item.email }}</span>
        </template>
        <template #header.phoneNumber>
          <span class="status">Phone Number</span>
        </template>
        <template v-slot:[`item.phoneNumber`] ="{item}">
          <span class="td-style">
            {{item.phoneNumber}}
          </span>
        </template>
        <template #header.date>
          <span class="status">Date</span>
        </template>
        <template v-slot:[`item.date`] ="{item}">
          <span class="td-style">
            {{item.dateOfSubscription}}
          </span>
        </template>
        <template v-slot:[`item.actions`]>
          <v-menu bottom offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <td class="d-flex justify-center">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon :color="color">mdi-dots-horizontal</v-icon>
                </v-btn>
              </td>
            </template>
            <v-list>
              <v-list-item class="" >
                <v-list-item-title
                    style="color: black"
                    class="tw-flex-row tw-flex tw-cursor-pointer menu-text">
                  <img
                      alt=""
                      class="tw-mr-2 icon-size"
                  />
                  View
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
  </div>
  <modal :dialog="showModal" :title="modalTitle"
         :description="modalDesc" icon="oops"
         @close="handleModal" @verifyTransportCompany="waitlist"
        :loading="modalLoading"/>
</div>
</template>

<script>
import Icons from "../components/reuseables/Icons";
import {waitlist} from "../services/api/APIService";
import Modal from "../components/reuseables/Modal";
import Avatar from "../components/reuseables/Avatar";
export default {
  name: "WaitListView",
  components: {Avatar, Modal, Icons},
  data(){
    return{
      color: "#004AAD",
      search : "",
      waitListData:[],
      not_found : "Transport Companies will show here",
      headers: [
        { text: "", value: "avatar" },
        { text: "", value: "firstName" },
        { text: "", value: "lastName" },
        { text: "", value: "email" },
        { text: "", value: "phoneNumber" },
        { text: "", value: "date" },
        { text: "", value: "actions", align: "end" },
      ],
      loading : false,
      showModal : false,
      modalLoading : false,
      modalTitle: "",
      modalDesc : " ",
      transportCompanyId:null
    }
  },

  methods: {
    handleModal() {
      this.showModal = !this.showModal

    },
    async waitlist() {
      this.loading = true
        await waitlist().then(res => {
         this.waitListData = res.data
          this.loading = false
        }).catch(err => {
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false
          })
          this.loading = false
        })
    },
  },
 async created() {
    await this.waitlist()
  }
}
</script>

<style scoped lang="scss">
.company-dashboard{
  display: flex;
  background: #FFFFFF;
  width: 100%;
  min-height: 82vh;
  border-radius: 10px;
  flex-direction: column;
}
.header{
  font-family: 'Inter', sans-serif ;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #004AAD;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.table{
  width: 100%;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  text-transform: capitalize;

}
.company-logo{
  height: 32px;
  width: 32px;
  border-radius: 50%;
}
</style>